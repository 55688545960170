<template>
  <footer class="footer bg-white small text-center text-black-50">
    <div class="container px-4 px-lg-5">
      <p>
        Copyright &copy;
        <a href="https://bdlg.mx" target="_blank">Bufete de la Garza</a>
        {{ new Date().getFullYear() }}
      </p>
      <p class="text-uppercase bold">
        <a class="px-2" href="#/">Inicio</a>
        <a class="px-2" href="#/abogados">Abogados</a>
        <a class="px-2" href="#/areas">Áreas de práctica</a>
        <a class="px-2" href="#/blog">Publicaciones</a>
        <a class="px-2" href="#/privacidad">Política de privacidad</a>
      </p>
    </div>
    <p class="text-small">
      Design by <a href="https://ittoni.xyz" target="_blank">ITTONI</a>
    </p>
  </footer>
</template>
<style>
.footer {
  padding: 2rem 0;
}

.footer a {
  text-decoration: none;
}

footer .text-small {
  font-size: 0.8em;
}
</style>
<script>
export default {
  name: "Footer",
  props: ["empresa"],
};
</script>
