<template>
  <nav
    class="navbar navbar-expand-lg navbar-light fixed-top navbar-shrink"
    :class="esMovil ? 'bg-darkk' : ''"
    id="mainNav"
  >
    <div class="container px-4 px-lg-5">
      <a class="navbar-brand" href="#/" @click="mostrarMenu = false">
        <img :src="empresa.completo" class="isologo1" />
        <img :src="empresa.iso" class="isologo2" />
      </a>
      <button
        class="navbar-toggler navbar-toggler-right"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarResponsive"
        aria-controls="navbarResponsive"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="mostrarMenu = !mostrarMenu"
      >
        Menu
        <i class="fas fa-bars"></i>
      </button>
      <div
        class="navbar-collapse"
        :class="!mostrarMenu ? 'collapse' : ''"
        id="navbarResponsive"
      >
        <ul class="navbar-nav ms-auto">
          <li
            class="nav-item"
            v-for="m in menu.filter((i) => i.mostrar)"
            :key="m.nombre"
          >
            <a
              class="nav-link"
              :href="'#/' + m.nombre"
              @click="mostrarMenu = false"
              >{{ m.label[lang] }}</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link text-uppercase" @click="setLang()">{{
              lang == "en" ? "es" : "en"
            }}</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<style scoped>
.isologo1 {
  height: 2.9em;
  margin-top: -0.25em;
  padding: 5px;
}

.isologo2 {
  display: none;
}

.navbar-brand {
  font-family: serif;
  /* TODO */
}

@media (max-width: 767px) {
  .navbar-collapse {
    height: 100vh;
    padding-left: auto;
    padding-right: auto;
    padding-top: 10vh;
    text-align: center;
  }

  .isologo1 {
    display: none;
  }

  .isologo2 {
    display: inherit;
    width: 1.8em;
  }
}
</style>
<script>
//import '../public/js/scripts.js';
import axios from "axios";
import Api from "@/components/Api";
export default {
  name: "Header",
  props: {
    empresa: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      lang: Api.obtenerLocal("lang") || "es",
      esMovil: Api.esMovil2(),
      mostrarMenu: false,
      posts: [],
    };
  },
  computed: {
    mostrarBlog: function () {
      return this.posts.length > 0;
    },
    menu: function () {
      return [
        {
          label: {
            es: "Abogados",
            en: "Attorneys",
          },
          nombre: "abogados",
          mostrar: true,
        },
        {
          label: {
            es: "Áreas de práctica",
            en: "Practice areas",
          },
          nombre: "areas",
          mostrar: true,
        },
        {
          label: {
            es: "Publicaciones",
            en: "Blog",
          },
          nombre: "blog",
          mostrar: this.mostrarBlog,
        },
      ];
    },
  },
  async mounted() {
    console.log("header lang", this.lang);
    let posts = await axios.post(this.url + "/readbypass/post");
    this.posts = posts.data;
  },
  methods: {
    setLang() {
      let l = this.lang == "es" ? "en" : "es";
      Api.guardarLocal("lang", l);
      window.location.reload();
    },
  },
};
</script>
