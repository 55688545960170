<template>
<div id="app">

  <Header :empresa="empresa" :lang="lang" />

  <router-view :empresa="empresa" />

  <Footer />

</div>
</template>
<script>
import Api from '@/components/Api'
import Header from '@/Header'
import Footer from '@/Footer'
export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      esMovil: Api.esMovil(),
      empresa: {
        nombre: 'BDLG',
        nombreLargo: 'Bufete de la Garza',
        logo: '../assets/512w/logo.png',
        iso: '../assets/512w/iso.png',
        isogris: '../assets/512w/isogris.png',
        completo: '../assets/512w/completo.png',
        slogan: {
          es: 'Firma de abogados',
          en: 'Lawers firm'
        },
        email: 'contacto@bdlg.mx',
        direccion: 'Humboldt No. 121, Colonia del Valle, 78200 SLP ',
        telefono: '444 811 4069',
      },
      lang: 'es',
      abogados: [{
        nombre: 'Abogado 1',
        imagen: ''
      }]
    }
  },
  mounted() {
    Api.guardarLocal('bdlg.mx.empresa', this.empresa)
    let vm = this
    console.log("BDLG página montada", this.esMovil)
    Api.$on('setLang', l => {
      vm.lang = l
    })
  },
  watch: {
    'lang': function(a) {
      Api.$emit('setLang', a)
      const lang = this.lang
      const l = window.location.href
      console.log("l?", l, lang)
      window.location.assign(l.replace('/' + lang, '/' + a))
      console.log("l!", window.location.href)
      window.location.reload()
    }
  }
}
</script>

<style>
@import "../public/css/main.css";
</style>
